import React from "react";
// import girl from  "../../assets/orkid.png"
// import calender from  "../../assets/tcc1.jpg"
// import ux from  "../../assets/ux-class.svg"
// import congrat from  "../../assets/congrat.svg"

// import Orkidvideo from "./Orkidvideo";
import OrkidLanding from "./OrkidLanding";
import OrkidDetails from "./OrkidDetails";
const Orkid = () => {
  return (
    <div className=" ">
       <OrkidLanding></OrkidLanding>
	   <OrkidDetails></OrkidDetails>

		{/* <Orkidvideo></Orkidvideo> */}
    </div>
  );
};

export default Orkid;
