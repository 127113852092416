import React from 'react';
import "./CamelliaLanding.css"
const CamelliaLanding = () => {
    return (
        <div>
    <section class="mb-40     ">
        <div className="grid grid-cols-2 ">
          <span class="[&>svg]:absolute [&>svg]:-z-10 [&>svg]:m-auto [&>svg]:block [&>svg]:w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              height="800"
              preserveAspectRatio="none"
              viewBox="0 0 1920 880"
            >
              <g transform="translate(960,440) scale(1,1) translate(-960,-440)">
                <linearGradient
                  id="lg-0.047955344060927496"
                  x1="0"
                  x2="1"
                  y1="0"
                  y2="0"
                >
                  <stop stop-color="hsl(217, 88%, 33.7%)" offset="0"></stop>
                  <stop stop-color="hsl(217, 88%, 75.1%)" offset="1"></stop>
                </linearGradient>
                <path d="" fill="url(#lg-0.047955344060927496)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="33.333333333333336s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="0s"
                    values="M0 0L 0 804.2328934685746Q 320 597.3613372284876  640 571.0708916590191T 1280 512.0661063245175T 1920 301.8788007488083L 1920 0 Z;M0 0L 0 877.6839081951588Q 320 668.0720922803877  640 649.0018928349388T 1280 328.7087077664202T 1920 162.95038242563396L 1920 0 Z;M0 0L 0 724.9886210051687Q 320 661.4364572061575  640 623.2173947479624T 1280 359.20353038907734T 1920 135.51673041732283L 1920 0 Z;M0 0L 0 804.2328934685746Q 320 597.3613372284876  640 571.0708916590191T 1280 512.0661063245175T 1920 301.8788007488083L 1920 0 Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.047955344060927496)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="33.333333333333336s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-6.666666666666667s"
                    values="M0 0L 0 765.7607191473613Q 320 641.7853945676919  640 624.2534689988059T 1280 365.27264408032966T 1920 190.38947978522663L 1920 0 Z;M0 0L 0 842.1984196370487Q 320 570.6690721707517  640 540.6844954979398T 1280 439.92879442880593T 1920 200.29713960445451L 1920 0 Z;M0 0L 0 796.6802345094818Q 320 721.9216894353016  640 696.8815669355181T 1280 373.6367381440213T 1920 196.63169821789495L 1920 0 Z;M0 0L 0 765.7607191473613Q 320 641.7853945676919  640 624.2534689988059T 1280 365.27264408032966T 1920 190.38947978522663L 1920 0 Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.047955344060927496)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="33.333333333333336s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-13.333333333333334s"
                    values="M0 0L 0 801.7562714943509Q 320 634.0247183381232  640 605.7090791951217T 1280 503.9393370140325T 1920 224.7551247480177L 1920 0 Z;M0 0L 0 821.0401780336218Q 320 670.8690783540507  640 637.0744123031742T 1280 456.40745286432224T 1920 278.1294357804296L 1920 0 Z;M0 0L 0 744.0534225112256Q 320 637.6425395409125  640 593.2079605185819T 1280 457.03995196824286T 1920 254.87693899994804L 1920 0 Z;M0 0L 0 801.7562714943509Q 320 634.0247183381232  640 605.7090791951217T 1280 503.9393370140325T 1920 224.7551247480177L 1920 0 Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.047955344060927496)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="33.333333333333336s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-20s"
                    values="M0 0L 0 817.8603658675457Q 320 592.9404308081629  640 559.1126621853513T 1280 428.9912604821798T 1920 209.017381620229L 1920 0 Z;M0 0L 0 802.0504889976935Q 320 561.3963273210122  640 537.6024084387631T 1280 430.41283267566695T 1920 256.1972069733954L 1920 0 Z;M0 0L 0 789.4448177495887Q 320 561.9675446430498  640 531.6192318019404T 1280 414.76018143244175T 1920 265.9163329632971L 1920 0 Z;M0 0L 0 817.8603658675457Q 320 592.9404308081629  640 559.1126621853513T 1280 428.9912604821798T 1920 209.017381620229L 1920 0 Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.047955344060927496)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="33.333333333333336s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-26.666666666666668s"
                    values="M0 0L 0 844.0541574423102Q 320 623.0697081316591  640 592.8483890737847T 1280 469.85448734523794T 1920 190.81850676853674L 1920 0 Z;M0 0L 0 871.4928294956283Q 320 618.9784567388518  640 593.1183717103518T 1280 376.5051942642811T 1920 141.32293927545027L 1920 0 Z;M0 0L 0 782.0118384610068Q 320 727.3267836497654  640 694.0476176759635T 1280 518.1545471640493T 1920 276.0053882957168L 1920 0 Z;M0 0L 0 844.0541574423102Q 320 623.0697081316591  640 592.8483890737847T 1280 469.85448734523794T 1920 190.81850676853674L 1920 0 Z"
                  ></animate>
                </path>
              </g>
            </svg>
          </span>
        </div>
			
             <div className=" ">
       <div className="camellia-back mb-6 ">
              <div class="  text-white">
		<div class="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start">
			{/* <!--Left Col--> */}
			<div class="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 items-start text-center lg:text-left mb-5 md:mb-0">
				<h1 data-aos="fade-right" data-aos-once="true" class="my-4 text-5xl font-bold leading-tight text-white">
					<span class="text-white">Studying</span> Online is now much easier
				</h1>
				<p data-aos="fade-down" data-aos-once="true" data-aos-delay="300" class="leading-normal text-2xl mb-8">Skilline is an interesting platform that will teach you in more an interactive way</p>
				<div data-aos="fade-up" data-aos-once="true" data-aos-delay="700" class="w-full md:flex items-center justify-center lg:justify-start md:space-x-5 py-6">
					<button class="lg:mx-0 bg-gradient-to-r from-[#575be8] via-[#160a2c] to-[#38235D] text-white text-xl font-bold rounded-full py-4 px-9 focus:outline-none transform transition hover:scale-110 duration-300 ease-in-out">
						Booking Now
					</button>
					{/* <div class="flex items-center justify-center space-x-3 mt-5 md:mt-0 focus:outline-none transform transition hover:scale-110 duration-300 ease-in-out">
						<button class="bg-white w-14 h-14 rounded-full flex items-center justify-center">
							<svg class="w-5 h-5 ml-2" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M22.5751 12.8097C23.2212 13.1983 23.2212 14.135 22.5751 14.5236L1.51538 27.1891C0.848878 27.5899 5.91205e-07 27.1099 6.25202e-07 26.3321L1.73245e-06 1.00123C1.76645e-06 0.223477 0.848877 -0.256572 1.51538 0.14427L22.5751 12.8097Z" fill="#23BDEE"/>
							</svg>
						</button>
						<span class="cursor-pointer">Watch how it works</span>
					</div> */}
				</div>
			</div>
			{/* <!--Right Col--> */}
			{/* <div class="w-full lg:w-6/12 lg:-mt-10 relative" id="girl">
				<img data-aos="fade-up" data-aos-once="true" class="w-10/12 mx-auto 2xl:-mb-20" src={girl} alt=''/>
			 
				<div data-aos="fade-up" data-aos-delay="300" data-aos-once="true" class="absolute top-20 -left-6 sm:top-32 sm:left-10 md:top-40 md:left-16 lg:-left-0 lg:top-52 floating-4">
					<img class="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16" src={calender} alt=''/>
				</div>
				 
				<div data-aos="fade-up" data-aos-delay="400" data-aos-once="true" class="absolute top-20 right-10 sm:right-24 sm:top-28 md:top-36 md:right-32 lg:top-32 lg:right-16 floating">
					<svg class="h-16 sm:h-24" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x="40" y="32" width="69" height="69" rx="14" fill="#F3627C"/></g><rect x="51.35" y="44.075" width="47.3" height="44.85" rx="8" fill="white"/><path d="M74.5 54.425V78.575" stroke="#F25471" stroke-width="4" stroke-linecap="round"/><path d="M65.875 58.7375L65.875 78.575" stroke="#F25471" stroke-width="4" stroke-linecap="round"/><path d="M83.125 63.9125V78.575" stroke="#F25471" stroke-width="4" stroke-linecap="round"/><defs><filter id="filter0_d" x="0" y="0" width="149" height="149" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="8"/><feGaussianBlur stdDeviation="20"/><feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.300438 0 0 0 0 0.396718 0 0 0 0.26 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>
				</div>
				 
				<div data-aos="fade-up" data-aos-delay="500" data-aos-once="true" class="absolute bottom-14 -left-4 sm:left-2 sm:bottom-20 lg:bottom-24 lg:-left-4 floating">
					<img class="bg-white bg-opacity-80 rounded-lg h-20 sm:h-28" src={calender} alt=""/>
				</div>
			 
				<div data-aos="fade-up" data-aos-delay="600" data-aos-once="true" class="absolute bottom-20 md:bottom-48 lg:bottom-52 -right-6 lg:right-8 floating-4">
					<img class="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16" src={congrat} alt=""/>
				</div>
			</div> */}
		</div>
		{/* <div class="text-white -mt-14 sm:-mt-24 lg:-mt-36 z-40 relative">
			<svg class="xl:h-40 xl:w-full" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
				<path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" fill="currentColor"></path>
			</svg>
			<div class="bg-white w-full h-20 -mt-px"></div>
		</div> */}
	</div>
        </div>

		{/* <Orkidvideo></Orkidvideo> */}
    </div>
	</section>
        </div>
    );
};

export default CamelliaLanding;