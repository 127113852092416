import React from "react";
import Sunflowerlanding from "./Sunflowerlanding";
import Sunflowerdetails from "./Sunflowerdetails";
 
const Sunflower = () => {
  return (
    <div>
     <Sunflowerlanding>	</Sunflowerlanding>
	 <Sunflowerdetails></Sunflowerdetails>
    </div>
  );
};

export default Sunflower;
