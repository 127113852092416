import React from 'react';
import AboutLanding from './AboutLanding';
import AboutDeatils from './AboutDeatils';
const About = () => {
  return (
    <div>
      <AboutLanding></AboutLanding>
      <AboutDeatils></AboutDeatils>
    </div>
  );
};

export default About;